.new-user-reg {
    color: #ffffff;
    font-size: 32px;
    padding-bottom: 16px;
}

.link-for-login {
    span {
        cursor: pointer;
        color: #68bbdb !important;
    }
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 40px;
        row-gap: 15px;

        .group {
            margin-top: unset !important;

            .label {
                color: #ffffff;
            }
        }
    }
}