$border-color: 1px solid #f0f1f2;

.flex-col {
    display: flex;
    flex-flow: column nowrap;
}

.flex-row {
    display: flex;
    flex-flow: row nowrap;
}

.omi-grid-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%; // max-width: 1024px;

    &.advancedResults {
        justify-content: flex-start;

        &.covariation-networks {
            margin-right: 0px;
        }

        &.sample-search-queries,
        &.recent-search-queries,
        &.saved-search-queries {
            .advancedResults {
                .row {
                    min-height: 50px !important;
                }

                .col {
                    padding: 0 5px !important;
                    padding-left: 10px !important;
                    align-items: center;
                }
            }
        }

        &.sample-search-queries {
            .advancedResults {
                .col {
                    &:nth-child(1) {
                        min-width: 5% !important;
                    }
                    &:nth-child(2) {
                        min-width: 50% !important;
                    }
                    &:nth-child(3) {
                        min-width: 30% !important;

                        & > div:first-child {
                            min-width: calc(100% - 35px);
                        }
                    }
                    &:nth-child(4) {
                        min-width: 15% !important;
                    }
                }
            }
        }

        &.recent-search-queries {
            .advancedResults {
                .col {
                    &:nth-child(1) {
                        min-width: 5% !important;
                    }
                    &:nth-child(2) {
                        min-width: 45% !important;

                        & > div:first-child {
                            min-width: calc(100% - 35px);
                        }
                    }
                    &:nth-child(3) {
                        min-width: 12.5% !important;
                    }
                    &:nth-child(4) {
                        min-width: 12.5% !important;
                    }
                    &:nth-child(5) {
                        min-width: 12.5% !important;
                    }
                    &:nth-child(6) {
                        min-width: 12.5% !important;
                    }
                }
            }
        }

        &.saved-search-queries {
            .advancedResults {
                .col {
                    &:nth-child(1) {
                        min-width: 5% !important;
                    }
                    &:nth-child(2) {
                        min-width: 35% !important;
                        width:540px;
                    }
                    &:nth-child(3) {
                        min-width: 30% !important;

                        & > div:first-child {
                            min-width: calc(100% - 35px);
                        }
                    }
                    &:nth-child(4) {
                        min-width: 10% !important;
                    }
                    &:nth-child(5) {
                        min-width: 10% !important;
                    }
                    &:nth-child(6) {
                        min-width: 10% !important;
                    }
                }
            }
        }

        &.action {
            margin-left: 0px;
            .advancedResults {
                .row {
                    .col {
                        padding: 19px 0 !important;
                    }
                }
            }
        }
    }

    .omi-grid {
        &-header {
            @extend .flex-col;

            &.geneSet {
                .row {
                    min-height: 32px;

                    .col {
                        &:nth-child(2) {
                            width: 8%;

                            & > div:first-child {
                                width: unset;
                            }
                        }
                    }
                }
            }

            &.advancedResults {
                .row {
                    .header-content {
                        align-items: center;
                    }
                }
            }
        }

        &-body {
            @extend .flex-col;

            .row {
                border-top: $border-color;

                &:last-child {
                    border-bottom: $border-color;
                }

                &:hover {
                    background-color: #f8f8f8;
                    box-shadow: 0 1px 0 0 #f0f1f2;
                }

                &.expand {
                    height: auto;
                    align-items: flex-start;

                    .body-content {
                        min-height: 32px;
                    }
                }

                .can-click {
                    cursor: pointer;
                }

                .empty {
                    width: 100% !important;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.geneSet {
                .row {
                    height: 44px;

                    .col-val {
                        line-height: 16px;
                    }
                }
            }

            &.files,
            &.globalSearchFileTypeResults {
                max-height: 400px;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 2px;
                }

                &::-webkit-scrollbar-track {
                    background: rgb(231, 231, 231);
                    border-radius: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-color: #a3a3a3;
                }
            }

            &.userManagement,
            &.userManagementForLab {
                max-height: 400px;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 2px;
                }

                &::-webkit-scrollbar-track {
                    background: rgb(231, 231, 231);
                    border-radius: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-color: #a3a3a3;
                }
            }

            &.microArray,
            &.mappingTables,
            &.nonMicroArray {
                max-height: 450px;
                overflow-x: hidden;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 2px;
                }

                &::-webkit-scrollbar-track {
                    background: rgb(231, 231, 231);
                    border-radius: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-color: #a3a3a3;
                }
            }

            &.ontologies,
            &.attributeTables {
                max-height: 450px;
                overflow-x: hidden;
                overflow-y: auto;
            }

            &.advancedResults {
                .row {
                    &:last-child {
                        border-bottom: 0.1px solid #f0f1f2;
                    }
                }
            }
        }

        &-footer {
            @extend .flex-row;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            .omi-btn {
                font-family: "Source Sans Pro Light";
                font-weight: unset;

                &-txt {
                    width: 170px;
                    margin-left: 5px;
                }
            }

            &.geneSet {
                .omi-btn {
                    .add {
                        width: 24px;
                    }
                    .omi-btn-txt {
                        margin-left: unset;
                    }
                }
            }
        }

        /* &-pagin {
            @extend .flex-row;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            .pagination {
                display: flex;
                flex-flow: row;
                align-items: center;
                list-style: none;
                padding: 0;

                li {
                    height: 36px;
                    width: 36px;
                    outline: none;
                    cursor: pointer;
                    border: 1px solid #dfe2e5;
                    border-left: 1px solid #dfe2e5;
                    border-right: none;

                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;

                    color: #444444;
                    font-family: "Source Sans Pro";
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;

                    a {
                        outline: none;
                    }
                }

                li.active {
                    a {
                        color: #2390bb;
                    }
                }

                li.previous {
                    font-size: 28px;
                    border-radius: 3px 0 0 3px;
                    border-right: none;
                }

                li.next {
                    font-size: 28px;
                    border-radius: 0 3px 3px 0;
                    border-left: 1px solid #dfe2e5;
                    border-right: 1px solid #dfe2e5;
                }

                a {
                    height: 100%;
                    width: 100%;

                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    justify-content: center;
                }
            }
        } */
    }

    .row {
        @extend .flex-row;
        min-height: 32px;
        padding: 0 20px;

        .header-content {
            @extend .flex-row;
            align-items: center;

            .col-title {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 18px;
                text-align: left; // right;
                cursor: pointer;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .col-sort {
                // background: url("../../../assets/sort-icon.svg") no-repeat;
                background: url("../../../assets/sort-svgrepo.svg") no-repeat;
                height: 11px;
                width: 11px;
                cursor: pointer;
                opacity: 0.45;

                margin-left: 5px;
                margin-top: 2px;

                &.asc {
                    background: url("../../../assets/descending-sort-svgrepo.svg") no-repeat;
                    transform: scaleX(-1);
                    opacity: unset;
                    // background-position-y: 5px;
                    // margin-top: -8px;
                }

                &.desc {
                    background: url("../../../assets/ascending-sort-svgrepo.svg") no-repeat;
                    transform: scaleX(-1);
                    opacity: unset;
                    // background-position-y: -5px;
                    // margin-top: 8px;
                }
            }
        }

        .body-content {
            @extend .flex-row;
            align-items: center;

            .col-val {
                color: #444444;
                font-family: "Source Sans Pro";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 18px;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.folder-icon {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/folder-icon.svg") no-repeat;
                }

                &.r-code-icon {
                    height: 19px;
                    width: 24px;
                    background: url("../../../assets/r-code-re.png") no-repeat;
                }

                &.pdf-icon {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/pdf-icon.svg") no-repeat;
                }

                &.xls-icon {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/xls-icon.svg") no-repeat;
                }

                &.txt-icon {
                    height: 20px;
                    width: 15px;
                    zoom: 1.5;
                    background: url("../../../assets/files-icon.svg") no-repeat;
                    background-position: -4px -3px;
                }

                &.user {
                    height: 32px;
                    width: 32px;
                    background: url("../../../assets/user-avatar.svg") no-repeat;
                    // position: relative;

                    .img-tooltip {
                        width: 104px;
                        padding: 5px 3px;
                        background-color: #000000;
                        color: #ffffff;
                        font-family: "Source Sans Pro";
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 12px;
                        text-align: center;
                        // visibility: hidden;
                        position: absolute;
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                        justify-content: center;
                        margin-left: -35px;
                        bottom: 90%; // 38px;

                        &::after {
                            content: "";
                            position: absolute;
                            right: 46px;
                            bottom: -12px;
                            border-width: 6px 6px 6px 6px;
                            border-style: solid;
                            border-color: #000000 transparent transparent transparent;
                        }
                    }

                    /* &:hover {
                        .img-tooltip {
                            visibility: visible;
                        }
                    } */
                }

                &.comments {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/comments-icon-hovered.svg") no-repeat;
                }

                &.copy {
                    outline: none;
                    height: 16px;
                    width: 35px;
                    background: url("../../../assets/copy.png") no-repeat;
                    cursor: pointer;
                    padding: 0;
                    margin-left: 12px !important;
                    background-position: bottom;
                }

                &.more {
                    outline: none;
                    height: 16px;
                    width: 16px;
                    background: url("../../../assets/ellipsis-hovered.svg") no-repeat;
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0;
                        pointer-events: none;
                    }

                    &.sampleAttributes {
                        position: relative;
                    }

                    &.fileTypeResults {
                        position: relative;
                        overflow: unset;
                    }

                    &.geneSet {
                        position: relative;
                        overflow: unset;
                    }
                }

                &.url-download {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/download-url-icon.svg") no-repeat;
                    cursor: pointer;
                }

                &.download {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/download-url-icon.svg") no-repeat;
                    cursor: pointer;
                }

                &.link {
                    color: #2390bb;
                    font-family: "Source Sans Pro";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 18px;
                    cursor: pointer;

                    &.ontologies {
                        &.no-link {
                            color: unset;
                            cursor: unset;
                        }
                    }

                    &.multi-val {
                        min-height: 100%;

                        & > span {
                            color: #2390bb;
                            margin-right: 5px;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        & > span.normal {
                            color: unset;
                            margin-right: 5px;
                            cursor: pointer;
                        }

                        &:hover {
                            position: absolute;
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            justify-content: flex-start;
                            top: 0px;
                            left: 0px;
                            text-overflow: unset;
                            white-space: unset;
                            overflow: unset;
                            line-height: 18px;
                            // padding: 12px 8px 12px;
                            padding: 12px;
                            background: aliceblue;
                            z-index: 10;
                        }
                    }
                }
                &.linkWithOnClick {
                    color: #2390bb;
                    font-family: "Source Sans Pro";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 18px;
                    cursor: pointer;
                }
                &.unread-download-log {
                    font-weight: 600;
                }
                &.commentNotifications {
                    width:90%;
                }

                &.show-history {
                    height: 20px;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    color: #2390bb;
                    font-family: "Source Sans Pro";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 12px;
                    padding-right: 2px;
                    cursor: pointer;

                    .down {
                        margin-left: 5px;
                        margin-top: -5px;
                    }

                    .right {
                        margin-left: 3px;
                        margin-top: 0;
                    }
                }

                &.deletable {
                    height: 24px;
                    width: 24px;
                    background: url("../../../assets/pop-over-delete-icon.svg") no-repeat;
                    cursor: pointer;
                }

                &:not(:first-child) {
                    margin-left: 10px;
                }

                &.normal {
                    margin-left: 0;
                }

                &.stringArray {
                    height: 50%;
                }
            }
        }
        .downloag-log-file-size-field {
            padding-left: 50px;
        }
        .comment-table-field {
            width: 30%;
        }

        .col-data {
            @extend .flex-col;
            justify-content: center;
            align-items: flex-start;
        }

        &:last-child {
            .body-content {
                .col-val {
                    &.link {
                        &:hover {
                            top: unset;
                            bottom: 0;
                            left: 0px;
                        }
                    }
                }
            }
        }
    }

    &.bordered {
        .col {
            border-left: 1px solid #dfe2e5;
            padding: 0 10px 0 10px;

            &:last-child {
                border-right: 1px solid #dfe2e5;
            }
        }

        .row {
            padding: unset;
            border-top: 1px solid #dfe2e5;

            .header-content {
                .col-title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: calc(100% - 15px);
                }
            }

            &:last-child {
                border-bottom: 1px solid #dfe2e5;
            }
        }

        .omi-grid {
            &-header {
                .row {
                    border-bottom: unset;
                }
            }
        }
    }

    .tool-tip-span {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        line-height: 18px;

        & > span {
            color: #2390bb;
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .recentFiles {
        input[type="checkbox"] {
            cursor: pointer;
        }

        .row {
            min-height: 50px;
        }

        .col {
            &:nth-child(1) {
                width: 4%;
            }
            &:nth-child(2) {
                width: 17%; // 28%;
            }
            &:nth-child(3) {
                width: 32%; // 17%;
            }
            &:nth-child(4) {
                width: 10%;
            }
            &:nth-child(5) {
                width: 6%;
            }
            &:nth-child(6) {
                width: 14%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            /* &:nth-child(8) {
                width: 4%;
                cursor: pointer;
            } */
            &:nth-child(8) {
                width: 3%;
                justify-content: flex-end;
            }
        }
    }

    .fileTypeResults {
        .row {
            min-height: 50px;
        }

        .col {
            /* &:nth-child(1) {
                width: 4%;
            } */
            &:nth-child(1) {
                width: 20%;
            }
            &:nth-child(2) {
                width: 37%;
                .col-val {
                    &:nth-child(2) {
                        width: 80%;
                        line-height: 18px;
                    }
                }
            }
            &:nth-child(3) {
                width: 10%;
            }
            &:nth-child(4) {
                width: 8%;
            }
            &:nth-child(5) {
                width: 12%;
            }
            &:nth-child(6) {
                width: 10%;
            }
            &:nth-child(7) {
                width: 3%;
                justify-content: flex-end;

                .col-val {
                    overflow: unset;
                }
            }

            padding-right: 1%;
        }

        .col-val {
            word-break: break-all;
        }
    }

    .globalSearchFileTypeResults {
        .row {
            min-height: 50px;
        }

        .col {
            &:nth-child(1) {
                width: 15%;
            }
            &:nth-child(2) {
                width: 20%;
                .col-val {
                    &:nth-child(2) {
                        width: 80%;
                        line-height: 18px;
                    }
                }
            }
            &:nth-child(3) {
                width: 8%;
            }
            &:nth-child(4) {
                width: 24%;
            }
            &:nth-child(5) {
                width: 8%;
            }
            &:nth-child(6) {
                width: 12%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 3%;
                justify-content: flex-end;

                .col-val {
                    overflow: unset;
                }
            }

            padding-right: 1%;
        }

        .col-val {
            word-break: break-all;
        }
    }

    .files {
        input[type="checkbox"] {
            cursor: pointer;
        }

        .row {
            min-height: 50px;
        }

        .col {
            &:nth-child(1) {
                width: 4%;
            }
            &:nth-child(2) {
                width: 20%;
            }
            &:nth-child(3) {
                width: 39%;
                .col-val {
                    &:nth-child(2) {
                        width: 80%;
                        line-height: 18px;
                    }
                }
            }
            &:nth-child(4) {
                width: 8%;
            }
            &:nth-child(5) {
                width: 6%;
            }
            &:nth-child(6) {
                width: 12%;
            }
            &:nth-child(7) {
                width: 8%;
            }
            /* &:nth-child(8) {
                width: 4%;
                cursor: pointer;
            } */
            &:nth-child(8) {
                width: 3%;
                justify-content: flex-end;
            }
        }

        .col-val {
            word-break: break-all;
        }
    }

    .dsFiles {
        .row {
            min-height: 50px;
            cursor: pointer;
        }

        .col {
            &:nth-child(1) {
                width: 26%;
                margin-left: 0.5%;
            }
            &:nth-child(2) {
                width: 12%;
            }
            &:nth-child(3) {
                width: 10%;
            }
            &:nth-child(4) {
                width: 12%;
            }
            &:nth-child(5) {
                width: 16%;
            }
            &:nth-child(6) {
                width: 14%; // 24%;
            }
        }

        .body-content {
            align-items: flex-start;
            .col-val {
                padding-top: 18px;
                padding-bottom: 18px;
                line-height: 15px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .advancedResults {
        .row {
            min-height: 50px;
            cursor: pointer;
            border: unset;
            padding: unset;

            &:hover {
                background-color: unset;
            }

            &:last-child {
                .col {
                    border-bottom: $border-color;
                }
            }
        }

        .col {
            width: 11.33%; // margin-left: 1%;
            padding-left: 1%;
            padding-right: 1%;

            /* &:nth-child(1) {
                width: 10.33%;
                margin-left: 2%;
            } */

            /* &:last-child {
                padding-right: 2%;
            } */

            border-left: $border-color;
            border-top: $border-color;

            &.pub-med-list {
                &:hover {
                    padding: unset !important;
                }
            }

            &:last-child {
                border-right: $border-color;
            }
        }

        .header-content {
            align-items: flex-start;

            .col-title {
                line-height: 15px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .body-content {
            align-items: flex-start;

            .col-val {
                padding-top: 18px;
                padding-bottom: 18px;
                line-height: 15px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            /* &.col {
                &:last-child {
                    border-top: 1px solid #f0f1f2 !important;
                }
            } */
        }
    }

    .analysisFiles {
        .row {
            min-height: 50px;
            cursor: pointer;
        }

        .col {
            &:nth-child(1) {
                width: 33%;
                margin-left: 2%;
            }
            &:nth-child(2) {
                width: 33%;
            }
            &:nth-child(3) {
                width: 33%;
            }
        }

        .body-content {
            align-items: flex-start;
            .col-val {
                padding-top: 18px;
                padding-bottom: 18px;
                line-height: 15px;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .ontologies,
    .attributeTables {
        .row {
            min-height: unset; // 32px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .col {
            &:nth-child(1) {
                width: 25%;
            }
            &:nth-child(2) {
                width: 25%; // 30%; // 25%;
            }
            &:nth-child(3) {
                width: 25%;
            }
            &:nth-child(4) {
                width: 25%;
            }
        }
    }

    .ontologies {
        .row {
            min-height: 32px;
            padding: 0 20px;
        }
    }

    .geneSetTables {
        .row {
            min-height: 32px;
        }

        .col {
            &:nth-child(1) {
                width: 25%;
            }
            /* &:nth-child(2) {
                width: 30%;
            } */
        }
    }

    .dataSetAttributes,
    .dataCollectionAttributes,
    .analysisAttributes {
        .row {
            min-height: 40px; // 32px;
        }

        .col-val {
            height: 50%;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .col {
            &:nth-child(1) {
                width: 10%;
            }
            &:nth-child(2) {
                width: 10%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 20%;
            }
            &:nth-child(5) {
                width: 6%;
            }
            &:nth-child(6) {
                width: 6%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 23%;
            }
            /* &:nth-child(9) {
                width: 3%;
                padding: 0 5px;
                justify-content: flex-end;
                border-left: none;

                .col-val {
                    overflow: unset;
                }
            } */
        }

        /* .header-content {
            &.col {
                &:nth-child(9) {
                    width: 3%;
                    border-left: none;
                }
            }
        } */
    }

    .sampleAttributes {
        .row {
            min-height: 40px; // 32px;
        }

        .col-val {
            height: 50%;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .col {
            &:nth-child(1) {
                width: 10%;
            }
            &:nth-child(2) {
                width: 10%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 20%;
            }
            &:nth-child(5) {
                width: 6%;
            }
            &:nth-child(6) {
                width: 6%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 20%;
            }
            &:nth-child(9) {
                width: 3%;
                padding: 0 5px;
                justify-content: flex-end;
                border-left: none;

                .col-val {
                    overflow: unset;
                }
            }
        }

        .header-content {
            &.col {
                &:nth-child(9) {
                    width: 3%;
                    border-left: none;
                }
            }
        }
    }

    .geneSet {
        .col-val {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .col {
            &:nth-child(1) {
                min-width: 10%;
            }
            &:nth-child(2) {
                min-width: 8%;

                & > div:first-child {
                    min-width: calc(100% - 24px);
                }
            }
            &:nth-child(3) {
                min-width: 10%;
            }
            &:nth-child(4) {
                min-width: 8%;
            }
            &:nth-child(5) {
                min-width: 9%;
            }
            &:nth-child(6) {
                min-width: 7.5%;
            }
            &:nth-child(7) {
                min-width: 9.5%;
            }
            &:nth-child(8) {
                min-width: 7%;
            }
            &:nth-child(9) {
                min-width: 16%;
            }
            &:nth-child(10) {
                min-width: 8%;
            }
            &:nth-child(11) {
                min-width: 7%;
            }
        }
    }

    .microArray {
        .row {
            min-height: 32px;
        }

        .col {
            padding: 0 5px;

            &:nth-child(1) {
                min-width: 30%;
            }
            /* &:nth-child(2) {
                min-width: 10%;
            } */
            &:nth-child(2) {
                min-width: 20%;
            }
            &:nth-child(3) {
                min-width: 15%;
            }
            &:nth-child(4) {
                min-width: 15%;
            }
            &:nth-child(5) {
                min-width: 15%;
            }
            &:nth-child(6) {
                min-width: 5%;
            }
        }
    }

    .nonMicroArray {
        .row {
            min-height: 32px;
        }

        .col {
            padding: 0 5px;

            &:nth-child(1) {
                min-width: 25%;
            }
            /* &:nth-child(2) {
                min-width: 15%;
            } */
            &:nth-child(2) {
                min-width: 20%;
            }
            &:nth-child(3) {
                min-width: 20%;
            }
            &:nth-child(4) {
                min-width: 20%;
            }
            &:nth-child(5) {
                min-width: 10%;
            }
        }
    }

    .userManagement {
        .row {
            min-height: 32px;
        }

        .col {
            padding: 0 5px;

            &:nth-child(1) {
                width: 12%;
            }
            &:nth-child(2) {
                width: 12%;
            }
            &:nth-child(3) {
                width: 10%;
            }
            &:nth-child(4) {
                width: 12%;
            }
            &:nth-child(5) {
                width: 8%;
            }
            &:nth-child(6) {
                width: 8%;
            }
            &:nth-child(7) {
                width: 10%;
            }
            &:nth-child(8) {
                width: 15%;
            }
            &:nth-child(9) {
                width: 9%;
            }
            &:nth-child(10) {
                width: 4%;
                justify-content: flex-end;
            }
        }
    }

    .userManagementForLab {
        .row {
            min-height: 32px;
        }
        .body-content {
            &.col {
                &:nth-child(4) {
                    padding-left: 12px;
                }

            }
        }

        .col {
            padding: 0 5px;

            &:nth-child(1) {
                min-width: 32%;
            }
            &:nth-child(2) {
                min-width: 32%;
            }
            &:nth-child(3) {
                min-width: 22%;
            }
            &:nth-child(4) {
                min-width: 10%;
            }
            &:nth-child(5) {
                min-width: 4%;
                justify-content: flex-end;
            }
        }
    }

    .mappingTables {
        .row {
            min-height: 32px;
        }

        .col {
            padding: 0 5px;

            &:nth-child(1) {
                min-width: 30%;
            }
            /* &:nth-child(2) {
                min-width: 15%;
            } */
            &:nth-child(2) {
                min-width: 20%;
            }
            &:nth-child(3) {
                min-width: 20%;
            }
            &:nth-child(4) {
                min-width: 20%;
            }
            &:nth-child(5) {
                min-width: 10%;
            }
            /* &:nth-child(7) {
                width: 10%;
            } */
        }

        .header-content {
            &.col {
                &:nth-child(1) {
                    width: 30%;
                    min-width: unset;
                }
                /* &:nth-child(2) {
                    width: 15%;
                    min-width: unset;
                } */
                &:nth-child(2) {
                    width: 20%;
                    min-width: unset;
                }
                &:nth-child(3) {
                    width: 20%;
                    min-width: unset;
                }
                &:nth-child(4) {
                    width: 20%;
                    min-width: unset;
                }
                &:nth-child(5) {
                    width: 10%;
                    min-width: unset;
                }
            }
        }
    }

    .lft-svg {
        height: 10px;
        width: 10px;
        background: url("../../../assets/left-arrow-svgrepo.svg") no-repeat;
    }

    .rgt-svg {
        height: 10px;
        width: 10px;
        background: url("../../../assets/left-arrow-svgrepo.svg") no-repeat;
        transform: rotate(180deg);
    }

    &.action {
        width: 75px;

        .row {
            padding-left: unset;
        }

        .omi-grid-header {
            .row {
                border-left: $border-color;
                border-right: $border-color;
                border-top: $border-color;
            }
        }

        .omi-grid-body {
            .row {
                .col {
                    width: 100%;
                    @extend .flex-row;
                    align-items: center;
                    justify-content: center;
                    padding: 17.5px 0;
                    // border-left: unset;

                    .col-val {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.scrollable {
            .omi-grid-body {
                .row {
                    height: 55.25px;

                    .col {
                        width: 100%;
                        padding: 18px 0;
                    }
                }
            }
        }
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 2px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-track {
        background: whitesmoke;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #dfe2e5;
    }
}

.wrap-data-and-actions {
    @extend .flex-row;
    justify-content: flex-start;
    .omi-grid-container {
        .row {
            padding-right: 0;
        }
    }
}

.omi-grid-pagin {
    @extend .flex-row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .pagination {
        display: flex;
        flex-flow: row;
        align-items: center;
        list-style: none;
        padding: 0;

        li {
            height: 36px;
            width: 36px;
            outline: none;
            cursor: pointer;
            border: 1px solid #dfe2e5;
            border-left: 1px solid #dfe2e5;
            border-right: none;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            color: #444444;
            font-family: "Source Sans Pro";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;

            a {
                outline: none;
            }
        }

        li.active {
            a {
                color: #2390bb;
            }
        }

        li.previous {
            font-size: 28px;
            border-radius: 3px 0 0 3px;
            border-right: none;
        }

        li.next {
            font-size: 28px;
            border-radius: 0 3px 3px 0;
            border-left: 1px solid #dfe2e5;
            border-right: 1px solid #dfe2e5;
        }

        a {
            height: 100%;
            width: 100%;

            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
        }
    }

    .lft-svg {
        height: 10px;
        width: 10px;
        background: url("../../../assets/left-arrow-svgrepo.svg") no-repeat;
    }

    .rgt-svg {
        height: 10px;
        width: 10px;
        background: url("../../../assets/left-arrow-svgrepo.svg") no-repeat;
        transform: rotate(180deg);
    }

    &.fileTypeResults,
    &.advancedResults {
        margin-top: 12px;
        margin-bottom: -12px;
    }
}