.content-item {
    height: 40px; // 35px;

    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 15px;

    background-color: #ffffff;
    border-bottom: 1px solid #f0f1f2;
    cursor: pointer;

    .img-collections {
        background: url("../../../assets/collections.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-analyses {
        background: url("../../../assets/analyses.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-resources {
        background: url("../../../assets/resources.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-starred {
        background: url("../../../assets/starred.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-tables {
        background: url("../../../assets/tables.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-roles,
    .img-user-management {
        background: url("../../../assets/roles.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .img-preferences {
        background: url("../../../assets/preferences.svg") no-repeat;
        height: 16px;
        width: 18px;
    }

    .content-label {
        color: #444444;
        font-family: "Source Sans Pro";
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;

        width: calc(100% - 32px);
        margin-left: 10px;
        height: 100%;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        -webkit-font-smoothing: inherit;   // to avoid extra bold look in mac safari

        label {
            cursor: pointer;
        }
    }

    .folder {
        background: url("../../../assets/folder.svg") no-repeat;
        height: 16px;
        width: 16px;
    }

    &:hover {
        background-color: #deedf4; // #deedf4; //#f8f8f8; // #dfe2e5

        .folder {
            background: url("../../../assets/folder-icon-blue.svg") no-repeat;
            height: 16px;
            width: 16px;
        }
    }

    &.selected {
        background-color: #f8f8f8;

        &:hover {
            background-color: #deedf4;
        }
    }
}